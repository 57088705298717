<template>
  <div>
    <template v-if="dreamer">
      <custom-breadcrumb :pageTitle="$t('models.dreamer')" :breadcrumb="breadcrumb"></custom-breadcrumb>

      <b-row>
        <b-col cols="12" lg="6">
          <info-card :school="school" :dreamer="dreamer" @refetch-data="getData(dreamer.id)" />

          <b-card v-if="!dreamer.deleted_at" no-body class="card-employee-task">
            <b-card-header>
              <b-card-title>{{ $t('forms.linked_students') }}</b-card-title>

              <b-button variant="link" class="pointer p-0" :to="{
                name: 'school-dreamers-edit',
                params: {
                  id: school.id,
                  student_id: dreamer.id,
                  tab: 1
                }
              }">
                <feather-icon icon="PlusIcon" size="18" class="cursor-pointer" />
                {{ $t('forms.link_student') }}
              </b-button>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <template v-for="(user) in dreamer.users">
                <div v-if="user.linked" :key="user.user_id"
                  class="employee-task d-flex justify-content-between align-items-center">
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar size="42" :text="avatarText(user.name)" variant="light-primary" />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        {{ user.name }}
                      </h6>
                      <small>{{ user.email }}</small>
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center pointer" @click="removeUser(user.id)"
                    v-b-tooltip.hover.left="$t('forms.unlink_student')">
                    <small class="m-75" style="color:tomato;">
                      <font-awesome-icon :icon="['fas', 'unlink']" size="lg" />
                    </small>
                  </div>
                </div>
              </template>

              <template v-for="(fa) in dreamer.family_account">
                <div :key="fa.id" class="employee-task d-flex justify-content-between align-items-center">
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar size="42" :text="avatarText(fa.name)" variant="light-primary" />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        {{ fa.name }}
                      </h6>
                      <small>{{ fa.email }}</small>
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center pointer" @click="removeUser(fa.id)"
                    v-b-tooltip.hover.left="$t('forms.unlink_family_account')">
                    <small class="m-75" style="color:tomato;">
                      <font-awesome-icon :icon="['fas', 'unlink']" size="lg" />
                    </small>
                  </div>
                </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" lg="6">
          <b-card v-if="!dreamer.deleted_at" no-body class="card-employee-task">
            <b-card-header>
              <b-card-title>{{ $t('Groups') }}</b-card-title>
              <b-button variant="link" class="pointer p-0" :to="{
                name: 'school-dreamers-edit',
                params: {
                  id: school.id,
                  student_id: dreamer.id,
                  tab: 2
                }
              }">
                <feather-icon icon="PlusIcon" size="18" class="cursor-pointer" />
                {{ $t('forms.add_to', { model1: $t('models.dreamer'), model2: $t('models.group') }) }}
              </b-button>
            </b-card-header>
            <!-- body -->
            <b-card-body>
              <template v-for="group in dreamer.groups">
                <div v-if="group.linked" :key="group.id"
                  class="employee-task d-flex justify-content-between align-items-center">
                  <b-media no-body>
                    <b-media-aside class="mr-75">
                      <b-avatar rounded size="42"
                        :src="require('@/assets/images/avatar/groups/' + (group.id_avatar || 'null') + '.png?v4')" />
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h6 class="mb-0">
                        <b-link :to="{
                          name: 'school-groups-view', params: {
                            id: school.id,
                            group_id: group.id
                          }
                        }">
                          {{ group.id == 0 ? $t('table.no_group') : group.name }}
                        </b-link>
                      </h6>
                      <!-- <small class="text-muted ">{{ dreamer.age }} years old</small> -->
                    </b-media-body>
                  </b-media>
                  <div class="d-flex align-items-center pointer" @click="removeGroup(group.id)"
                    v-b-tooltip.hover.left="$t('forms.unlink_group')">
                    <small class="m-75" style="color:tomato;">
                      <font-awesome-icon :icon="['fas', 'unlink']" size="lg" />
                    </small>
                  </div>
                </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    <h2 class="mt-3 mb-2">
      {{$t('learning_path.summary')}}
    </h2>
    <dreamer-statistics :analytics="analytics" :dreamer="dreamer" />
    <b-row>
      <b-col cols="12">
        <b-row class="match-height">
          <b-col md="6">
            <DatosGeneralesDeUso :dreamer="dreamer" />
          </b-col>
          <b-col md="6">
            <TiemposDeAprendizaje :dreamer="dreamer"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <UsoPorAreasAcademicas :dreamer="dreamer"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
        <!-- ANALÍTICAS DE USO -->
        <b-col cols="12" class="mb-1">
          <h2 class="mb-2">{{$t('analytics.usage')}}</h2>
          <ContenidosMasUtilizados :dreamer="dreamer"/>
        </b-col>
        <b-col cols="12">
          <h2 class="mb-2">{{$t('analytics.progress')}}</h2>
          <GraficosDeProgreso :dreamer="dreamer"/>
        </b-col>

      </b-row>
    </template>
    <template v-else>
      <b-alert variant="danger" :show="dreamer === undefined">
        <h4 class="alert-heading">
          Error fetching data
        </h4>
        <div class="alert-body">
          No data found with this id. Go back to
          <b-link class="alert-link" :to="{ name: 'schools' }">
            Schools List
          </b-link>
        </div>
      </b-alert>
    </template>

  </div>
</template>

<script>
import UsoPorAreasAcademicas from "@/views/dreamers/components/dreamer-charts/UsoPorAreasAcademicas.vue";
import DatosGeneralesDeUso from "@/views/dreamers/components/dreamer-charts/DatosGeneralesDeUso.vue";
import TiemposDeAprendizaje from "@/views/dreamers/components/dreamer-charts/TiemposDeAprendizaje.vue";
import ContenidosMasUtilizados from '@/views/dreamers/components/dreamer-charts/ContenidosMasUtilizados.vue'
import GraficosDeProgreso from '@/views/dreamers/components/GraficosDeProgreso.vue'

import { schoolDreamerUnlink, fetchSchoolDreamer } from '@/api/routes'
import router from '@/router'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import DreamerStatistics from '@/views/schools/dreamers/view/DreamerStatistics.vue'
import InfoCard from './view/InfoCard.vue'
import {
  BRow, BCol, BButton, VBTooltip,
  BCard, BCardTitle, BCardHeader, BCardBody, BMedia,
  BMediaAside, BAvatar, BMediaBody, BAlert, BLink, BModal
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'


export default {
  components: {
    BRow, BCol, BAlert, BLink, BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, BMediaAside, BAvatar, BMediaBody, BModal,

    DreamerStatistics,
    CustomBreadcrumb,
    InfoCard,
    DatosGeneralesDeUso,
    UsoPorAreasAcademicas,
    TiemposDeAprendizaje,
    ContenidosMasUtilizados,
    GraficosDeProgreso, 
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    return {
      avatarText
    }
  },
  data() {
    return {
      school: {},
      dreamer: null,
      analytics: {},
    }
  },
  mounted() {
    let id = router.currentRoute.params.dreamer_id;
    this.getData(id)
  },
  computed: {
    breadcrumb() {
      if (this.$store.getters['user/isSchool']) {
        return [
          {
            text: this.$t('Schools'),
          },
          {
            text: this.school.name
          },
          {
            to: 'school-dreamers-list',
            text: this.$t('Dreamers')
          },
          {
            text: this.$t('Detail'),
            active: true,
          },
        ]
      } else {
        return [
          {
            text: this.$t('Schools'),
            to: 'schools'
          },
          {
            to: 'school-view',
            params: {
              id: this.school.id,
            },
            text: this.school.name
          },
          {
            to: 'school-dreamers-list',
            text: this.$t('Dreamers')
          },
          {
            text: this.$t('Detail'),
            active: true,
          },
        ]
      }
    }
  },
  methods: {
    makeToast(variant = null, tit = '', msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    async removeUser(user_id) {
  try {
    const confirmed = await this.$bvModal.msgBoxConfirm(this.$t('forms.confirmations_unlink_user'), {
      title: this.$t('forms.unlink_user'),
      size: 'sm',
      okVariant: 'primary',
      okTitle: this.$t('Yes'),
      cancelTitle: this.$t('No'),
      cancelVariant: 'outline-secondary',
      hideHeaderClose: false,
      centered: true,
    });

    if (confirmed) {
      let data = {
        'dreamer_id': this.dreamer.id,
        'tab': 'user',
        'user_id': user_id
      }
      const response = await this.$http.post(schoolDreamerUnlink, data);

      if (response.data.status == 200) {
        this.makeToast('success', this.$t('Success'), this.$t('forms.unlink_student_success'));
        this.getData(this.dreamer.id)
      } else {
        this.makeToast('danger', this.$t('Error'), response.data.msg);
      }
    }
  } catch (error) {
    // console.log("Err -> ", error);
    this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
  }
},
    async removeGroup(group_id) {
      try {
        const confirmed = await this.$bvModal.msgBoxConfirm(this.$t('forms.confirmations_unlink_group'), {
          title: this.$t('forms.unlink_group'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        });

        if (confirmed) {
          let data = {
            'dreamer_id': this.dreamer.id,
            'tab': 'group',
            'group_id': group_id
          }
          const response = await this.$http.post(schoolDreamerUnlink, data);

          if (response.data.status == 200) {
            this.makeToast('success', this.$t('Success'), this.$t('forms.unlink_group_success'));
            this.getData(this.dreamer.id)
          } else {
            this.makeToast('danger', this.$t('Error'), response.data.msg);
          }
        }
      } catch (error) {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      }
    },
    async getData(id) {
      let data = {
        'id': id,
        'school_id': this.$route.params.id,
      }
      await this.$http.post(fetchSchoolDreamer, data).then(response => {
        // console.log("response.data.dreamer", response.data.dreamer)
        this.school = response.data.school;
        this.dreamer = response.data.dreamer;
        this.analytics = response.data.analytics;
      }).catch(error => {
        // console.log("Err -> ", error);
        this.makeToast('danger', this.$t('Error'), this.$t('error_retry_later'));
      })
    },
  }
}
</script>

<style></style>
<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="require('@/assets/images/avatar/AvatarSprite_'+(dreamer.avatar||'57')+'.png?v4')"

            :text="avatarText(dreamer.name)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 break-word">
                {{ dreamer.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', 'SchoolDreamers')">
                <b-button
                  class="mr-1"
                  :to="{ 
                    name: 'school-dreamers-edit', 
                    params: { 
                      id: school.id, 
                      dreamer_id: dreamer.id, 
                    } 
                  }"
                  variant="primary"
                  v-if="!dreamer.deleted_at"
                >
                  {{$t('forms.edit')}}
                </b-button>
              </template>
              <template v-if="$can('delete', 'Schools')">
                <b-button
                  variant="outline-danger"
                  class="mr-1"
                  v-if="!dreamer.deleted_at"
                  @click="deleteModel"
                >
                  {{$t('forms.delete')}}
                </b-button>
              </template>
              <template v-if="$can('update', 'Schools')">
                <b-button
                  variant="outline-danger"
                  class="mr-1"
                  v-if="dreamer.deleted_at"
                  @click="recoverModel"
                >
                  {{$t('forms.recover')}}
                </b-button>
              </template>
              <b-button
                variant="outline-secondary"
                type="button"
                class="mr-1"
                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ name: 'school-dreamers-list', params: { id: school.id } })"
                
              >
                {{ $t('forms.go_back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
      >
        <table class="mt-2 w-100">
          
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="GiftIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.f_nac')}}</span>
            </th>
            <td class="pb-50">
              {{ dreamer.birthdate  }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.age')}}</span>
            </th>
            <td class="pb-50">
              {{ dreamer.age  }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="HashIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.registration_number')}} 
                <feather-icon
                  v-b-tooltip.hover.bottom="$t('fields.registration_number_info')"
                  icon="HelpCircleIcon"
                  size="16"
                  class="text-muted cursor-pointer ml-1"
                /></span>
            </th>
            <td class="pb-50">
              {{ dreamer.registration_number  }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StopCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Smilies</span>
            </th>
            <td class="pb-50">
              {{ dreamer.coins  }}
            </td>
          </tr>
        
         <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.created_at')}}</span>
            </th>
            <td>
              {{ new Date(dreamer.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="EditIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('fields.updated_at')}}</span>
            </th>
            <td>
              {{ new Date(dreamer.updated_at).toLocaleDateString() }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { deleteSchoolDreamer, recoverSchoolDreamer } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,VBTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dreamer: {
      type: Object,
      required: true,
    },
    school:{
      type: Object,
      required:true
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm(this.$t('table.deactivate_dreamers_recover')+' '+this.$t('table.deactivate_one_confirmation'), {
          title: this.$t('table.deactivate'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'id': this.dreamer.id
            }
            this.$http.post(deleteSchoolDreamer, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    recoverModel(){
      this.$bvModal
        .msgBoxConfirm(this.$t('forms.recover_confirmation'), {
          title: this.$t('forms.recover'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'id': this.dreamer.id
            }
            this.$http.post(recoverSchoolDreamer, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

              } else {
                this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
              this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
            })
          }
        })
    },
  }
}
</script>

<style>

</style>
